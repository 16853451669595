import React from 'react';
import styles from "./calculatedShippingCost.module.css";
import {getFormattedPrice} from "../utils/numberFormatting";

const CalculatedShippingCost = ({cost}) => {
    const shippingMessage = cost > 0 ? `Shipping is ${getFormattedPrice(cost)}` : `Enjoy shipping on us with over $100.00 of purchases!`;
    return (
        <article className={styles.container}>
            <p>{shippingMessage}</p>
        </article>
    )
}

export default CalculatedShippingCost;



