import React, {useContext} from "react";
import styles from './cartItem.module.css';
import {getFormattedPrice} from "../utils/numberFormatting";
import SelectedProductContext from "../context/selectedProductsContext";

function CartItem({itemName, itemPrice, imageSource, id, quantity}) {
    const formattedPrice = getFormattedPrice(itemPrice * quantity);

    const selectedProducts = useContext(SelectedProductContext);

    const removeFromCart = (idOfProductToRemove) => {
        const newProductArray = selectedProducts.selectedProducts.filter(function(product){
            return product.id !== idOfProductToRemove;
        })

        selectedProducts.setSelectedProducts(newProductArray);
    }

    return (
        <article
            className={styles.container}
        >
            <img alt={`Photograph of an ${itemName} product`} src={imageSource}/>
            <section
                className={styles.nameAndQuantity}
            >
                <span>{quantity} x </span>
                <h3>{itemName}</h3>
            </section>
            <span>{formattedPrice}</span>
            <button onClick={(event => removeFromCart(id))}>Remove</button>
        </article>
    );
}

export default CartItem;
