import React, {useContext} from 'react';
import SelectedProductContext from '../context/selectedProductsContext';
import styles from "./cart.module.css";
import CartItem from "../components/cartItem";
import Layout, {designSystem} from "../components/layout";
import SEO from "../components/seo";
import headerStyles from "../components/header.module.css";
import Header from "../components/header";
import {Link} from "gatsby";
import {getFormattedPrice} from "../utils/numberFormatting";
import {SHIPPING_COSTS} from "../utils/constants";
import CalculatedShippingCost from "../components/calculatedShippingCost";

const Cart = () => {
    const selectedProducts = useContext(SelectedProductContext);

    const getTotalCostOfProducts = () => {
        return selectedProducts.selectedProducts.reduce((total, product) => {
            return total + (product.price * product.quantity);
        }, 0);
    }

    const getTotalCostOfShippingAndProducts = () => {
        return getTotalCostOfProducts() + SHIPPING_COSTS.STANDARD_SHIPPING;
    }

    return (
        <Layout>
            <SEO title="Cart"/>
            <div
                className={styles.allPage}
            >
                <div
                    className={`${headerStyles.header}`}
                >
                    <Header
                        headerTextColour={designSystem.palette.black}
                        backgroundColour={designSystem.palette.white}
                        backgroundColourSmallScreen={designSystem.palette.white}
                        showSmallHeading={true}
                    />
                </div>
                {selectedProducts.selectedProducts.length > 0 ? (
                        <div
                            className={styles.content}
                        >
                            <h2>Products in Your Cart</h2>
                            <ul>
                                {selectedProducts.selectedProducts.map(product => (
                                    <li key={product.id}>
                                        <CartItem
                                            itemName={product.name}
                                            itemPrice={product.price}
                                            imageSource={product.image}
                                            id={product.id}
                                            quantity={product.quantity}
                                        />
                                    </li>
                                ))}
                            </ul>
                            <CalculatedShippingCost cost={SHIPPING_COSTS.STANDARD_SHIPPING} />
                            <section
                                className={styles.goToShippingContainer}
                            >
                                <Link
                                    className={styles.cancel}
                                    to={'shop'}
                                >Cancel</Link>
                                <Link
                                    to={'shipping'}
                                    state={{shipping: SHIPPING_COSTS}}
                                    className={styles.gotToShippingButton}
                                >
                                    {`Enter Shipping Details • ${getFormattedPrice(getTotalCostOfShippingAndProducts())}`}
                                </Link>
                            </section>
                        </div>) :
                    (
                        <div
                            className={styles.emptyMessage}
                        >
                            <h2>Looks like your cart is empty</h2>
                            <p>Please head to the <Link to={'shop'}>shop</Link> to see all of the beautiful products we
                                have on offer.</p>
                            <Link
                                to={'shop'}
                            >
                                <img
                                    alt={'Woman looking at art'}
                                    src={'https://res.cloudinary.com/cleanswelllines/image/upload/v1581135858/bodallaLavender/undraw_art_0tat_vem1eq.svg'}
                                />
                            </Link>
                        </div>
                    )
                }
            </div>
        </Layout>
    )
}

export default Cart;



